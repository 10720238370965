@import '../../theme';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  overflow-x: hidden;
  overflow-y: auto;

  background-color: rgba($color-dark-sky, 0.5);

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  max-width: 1000px;
  margin: 2rem auto;
  font-family: inherit;
  background-color: $color-snow;

  &.overflow {
    overflow: hidden;
  }

  &.small {
    width: 560px;
  }

  &.big {
    position: absolute;
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;

    display: flex;

    flex-direction: column;

    max-width: none;
    margin: 0;
    overflow: hidden;

    .content {
      flex-grow: 1;

      iframe {
        height: 100%;
      }
    }
  }

  &.dialog {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;

    width: 400px;
    padding: 5rem;
    padding-bottom: 0;
    margin-right: -50%;

    transform: translate(-50%, -50%);

    .header {
      justify-content: center;

      padding: 0 4rem;

      text-align: center;

      background-color: transparent;
      box-shadow: none;

      h2 {
        margin-bottom: 2rem;
      }
    }

    .content {
      padding: 0;

      span {
        margin-bottom: 4rem;
      }

      button {
        justify-content: center;

        &:not(:last-of-type) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &.nofooter .content {
    padding-bottom: 4rem;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 4rem 0;

  &.hasSubtitle h2 {
    margin-bottom: 2rem;
  }

  button {
    @include unstyled-button(4rem);

    padding: 0;
    margin: 0;
    border-radius: .5rem;

    &:hover {
      color: $color-warm-sand;
      background-color: $color-grey;

      svg {
        fill: $color-warm-sand;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  border-radius: 4px;

  &.noPadding {
    padding: 0;
  }
}

.footer {
  padding: 4rem;
  text-align: right;
  border-top: 1px solid $color-warm-grey;

}

[class~='ReactModal__Overlay'] {
  opacity: 0;
  transition: opacity 100ms linear;
}

[class~='ReactModal__Overlay--after-open'] {
  opacity: 1;
}

[class~='ReactModal__Overlay--before-close'] {
  opacity: 0;
}

.loadingScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
