@import '../../../../../theme';

.listItem {
  display: block;
  flex-shrink: 0; // IE 11
}

.wrapper {
  display: block;

  flex: 1;
  flex-direction: column;

  align-items: flex-start;

  justify-content: center;

  margin-top: size(8);
  margin-bottom: size(8);
}

.title {
  font-family: $font-bold;
  font-size: 2rem;
  color: $color-cloudy-sky;
}

.content {
  margin-top: 1.66rem;

  font-size: 2.166rem;
  font-weight: 500;
  line-height: 2.33rem;
  color: $color-dark-sky;

  &.sentimentMarkerWrapper {
    margin-top: 0.5rem;
  }
}