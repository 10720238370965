@function size($size) {
  @return $size * $base-size-unit;
}

@function op-pallet($color-name) {
  $color-name: inspect($color-name);
  
  @if $color-name == 'pink' or $color-name == '"pink"' {
    @return $color-light-berry;
  
  }

  @else if $color-name == 'red' or $color-name == '"red"' {
    @return $color-berry;
  
  }

  @else if $color-name == 'turquoise' or $color-name == '"turquoise"' {
    @return $color-light-sea;
  
  }

  @else if $color-name == 'darkblue' or $color-name == '"darkblue"' {
    @return $color-sea;
  
  }

  @else if $color-name == 'lightgreen' or $color-name == '"lightgreen"' {
    @return $color-moss;
  
  }

  @else if $color-name == 'green' or $color-name == '"green"' {
    @return $color-forest;
  
  }

  @else if $color-name == 'yellow' or $color-name == '"yellow"' {
    @return $color-cold-sand;
  
  }

  @else if $color-name == 'orange' or $color-name == '"orange"' {
    @return $color-warm-sand;
  
  }

  @else if $color-name == 'blue' or $color-name == '"blue"' {
    @return $color-blue-sky;
  
  }

  @else {
    @return $color-cloudy-sky;
  }
}

@function highlight-pallet($color-name) {
  $color-name: meta.inspect($color-name);
  
  @if $color-name == 'red' or $color-name == '"red"' {
    @return #FFC2C5;
  
  }

  @else if $color-name == 'orange' or $color-name == '"orange"' {
    @return #FDD590;
  
  }

  @else if $color-name == 'yellow' or $color-name == '"yellow"' {
    @return #FDD590;
  
  }

  @else if $color-name == 'green' or $color-name == '"green"' {
    @return #89EABC;
  
  }

  @else if $color-name == 'turquoise' or $color-name == '"turquoise"' {
    @return #DCDADD;
  
  }

  @else if $color-name == 'blue' or $color-name == '"blue"' {
    @return #A0DEF8;
  
  }

  @else if $color-name == 'darkblue' or $color-name == '"darkblue"' {
    @return #B7D2F7;
  
  }

  @else if $color-name == 'lightgreen' or $color-name == '"lightgreen"' {
    @return #E1F9ED;
  
  }

  @else if $color-name == 'pink' or $color-name == '"pink"' {
    @return #FFC2C5;
  
  }

  @else {
    @return #DCDADD;
  }
}

/* use for stripping button style */

@mixin unstyled-button($size) {
  width: $size;
  height: $size;

  font-size: $size;
  line-height: $size;

  cursor: pointer;

  background-color: transparent;
  border: 0;
  outline: none;
}

@mixin no-selection { /* Safari */ /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}